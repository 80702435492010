import React from "react";

import Main from "../Main";
let Home = React.lazy(() => import("../page/Home"));
let GetStarted = React.lazy(() => import("../docs/GetStarted"));
let Styles = React.lazy(() => import("../docs/Styles"));

//component
let Button = React.lazy(() => import("../docs/Component/Button"));
let TextBox = React.lazy(() => import("../docs/Component/text-box"));
let CheckBox = React.lazy(() => import("../docs/Component/check-box"));
let SearchBox = React.lazy(() => import("../docs/Component/search-box"));
let SelectionList = React.lazy(() => import("../docs/Component/selection-list"));
let DropDown = React.lazy(() => import("../docs/Component/dropdown"));
let FormGroup = React.lazy(() => import("../docs/Component/form-group"));
let Popup = React.lazy(() => import("../docs/Component/popup"));
let PanelBox = React.lazy(() => import("../docs/Component/panel-box"));
let Menu = React.lazy(() => import("../docs/Component/menu"));
let NumberBox = React.lazy(() => import("../docs/Component/number-box"));
let SelectBox = React.lazy(() => import("../docs/Component/select-box"));
let ToastMessage = React.lazy(() => import("../docs/Component/Toast-message"));
let CalendarBox = React.lazy(() => import("../docs/Component/calendar-box"));
let DatePicker = React.lazy(() => import("../docs/Component/date-picker"));
let Label = React.lazy(() => import("../docs/Component/label"));
let TreeView = React.lazy(() => import("../docs/Component/tree-view"));
let Dialog = React.lazy(() => import("../docs/Component/dialog"));
let Popover = React.lazy(() => import("../docs/Component/popover"));
let RadioButton = React.lazy(() => import("../docs/Component/radiobutton"));
let Switch = React.lazy(() => import("../docs/Component/switch"));
let Scheduler = React.lazy(() => import("../docs/Component/scheduler"));
let DataGrid = React.lazy(() => import("../docs/Grids/data-grid"));
let PasswordBox = React.lazy(() => import("../docs/Component/password-box"));
let TextArea = React.lazy(() => import("../docs/Component/textarea"));
let ProgressBar = React.lazy(() => import("../docs/Component/progress-bar"));
let UploadFile = React.lazy(() => import("../docs/Component/upload-file"));
let TabView = React.lazy(() => import("../docs/Component/tab-view"));
let FieldSet = React.lazy(() => import("../docs/Component/fieldset"));
let FilterPanel = React.lazy(() => import("../docs/Component/filter-panel"));
let Toolbar = React.lazy(() => import("../docs/Component/toolbar"));
let DataSource = React.lazy(() => import("../docs/Component/data-source"));
let Validation = React.lazy(() => import("../docs/Component/validation"));
let ContextMenu = React.lazy(() => import("../docs/Component/context-menu"));
let QueryBuilder = React.lazy(() => import("../docs/Component/query-builder"));
let ColorPicker = React.lazy(() => import("../docs/Component/color-picker"));
let Drawer = React.lazy(() => import("../docs/Component/drawer"));
let LoadingIndicator = React.lazy(() => import("../docs/Component/loading-indicator"));
let LoadingPanel = React.lazy(() => import("../docs/Component/loading-panel"));
let CustomPopup = React.lazy(() => import("../docs/Component/custom-popup"));
let TimePicker = React.lazy(() => import("../docs/Component/time-picker"));
let ComponentsGetStarted = React.lazy(() => import("../docs/ComponentsGetStarted"));

//grids
let GridsGetStarted = React.lazy(() => import("../docs/GridsGetStarted"));
let DataLOV = React.lazy(() => import("../docs/Grids/data-lov"));
let DataTree = React.lazy(() => import("../docs/Grids/data-tree"));
let CommandColumn = React.lazy(() => import("../docs/Grids/command-column"));
let DateColumn = React.lazy(() => import("../docs/Grids/date-column"));
let SelectionColumn = React.lazy(() => import("../docs/Grids/selection-column"));
let TextColumn = React.lazy(() => import("../docs/Grids/text-column"));
let RowNumber = React.lazy(() => import("../docs/Grids/row-number-column"));

//Layout
let LayoutsGetStarted = React.lazy(() => import("../docs/LayoutsGetStarted"));
let DockLayout = React.lazy(() => import("../docs/Layouts/dock-layout"));
let DockPanel = React.lazy(() => import("../docs/Layouts/dock-panel"));
let DockPanelContent = React.lazy(() => import("../docs/Layouts/dock-panel-content"));

//service
let ComponentFactoryService = React.lazy(() => import("../docs/Service/component-factory"));
let EventService = React.lazy(() => import("../docs/Service/event"));
let InjectionService = React.lazy(() => import("../docs/Service/injection"));
let StorageService = React.lazy(() => import("../docs/Service/storage"));
let NavigatorService = React.lazy(() => import("../docs/Service/navigator"));
let TranslatorService = React.lazy(() => import("../docs/Service/translator"));
let DialogService = React.lazy(() => import("../docs/Service/dialog"));
let PopupService = React.lazy(() => import("../docs/Service/popup"));
let LoadingService = React.lazy(() => import("../docs/Service/loading"));

// class
let AXSelectItem = React.lazy(() => import("../docs/Class/ax-select-item"));
let AXSelectionList = React.lazy(() => import("../docs/Class/ax-selection-list"));
let AXNavigatorParam = React.lazy(() => import("../docs/Class/ax-navigator-param"));
let AXBaseMenuItem = React.lazy(() => import("../docs/Class/ax-base-menu-item"));
let AXMenuItem = React.lazy(() => import("../docs/Class/ax-menu-item"));
let AXButtonItem = React.lazy(() => import("../docs/Class/ax-button-item"));
let AXCheckItem = React.lazy(() => import("../docs/Class/ax-check-item"));
let AXColor = React.lazy(() => import("../docs/Class/ax-color"));
let ClosingEventArgs = React.lazy(() => import("../docs/Class/closing-event-args"));
let ClosingAction = React.lazy(() => import("../docs/Class/closing-action"));
let ClosedEventArgs = React.lazy(() => import("../docs/Class/closed-event-args"));
let AXCalendarMonth = React.lazy(() => import("../docs/Class/ax-calendar-month"));
let AXPromise = React.lazy(() => import("../docs/Class/ax-promise"));
let AXDateTime = React.lazy(() => import("../docs/Class/ax-date-time"));
let AXDateTimeRange = React.lazy(() => import("../docs/Class/ax-date-time-range"));
let AXColorUtil = React.lazy(() => import("../docs/Class/ax-color-util"));
let Test = React.lazy(() => import("../docs/Class/test"));
let AXBasePageComponent = React.lazy(() => import("../docs/Class/ax-base-page-component"));

const MAIN_ROUTES = [
  { path: "/get-started", component: GetStarted },
  { path: "/styles", component: Styles },

  //components
  { path: "/components", component: Button },
  { path: "/buttons", component: Button },
  { path: "/text-box", component: TextBox },
  { path: "/check-box", component: CheckBox },
  { path: "/search-box", component: SearchBox },
  { path: "/selection-list", component: SelectionList },
  { path: "/drop-down", component: DropDown },
  { path: "/form-group", component: FormGroup },
  { path: "/popup", component: Popup },
  { path: "/panel-box", component: PanelBox },
  { path: "/menu", component: Menu },
  { path: "/number-box", component: NumberBox },
  { path: "/select-box", component: SelectBox },
  { path: "/toast-message", component: ToastMessage },
  { path: "/calendar-box", component: CalendarBox },
  { path: "/date-picker", component: DatePicker },
  { path: "/label", component: Label },
  { path: "/tree-view", component: TreeView },
  { path: "/dialog", component: Dialog },
  { path: "/popover", component: Popover },
  { path: "/radiobutton", component: RadioButton },
  { path: "/switch", component: Switch },
  { path: "/scheduler", component: Scheduler },
  { path: "/password-box", component: PasswordBox },
  { path: "/textarea", component: TextArea },
  { path: "/progress-bar", component: ProgressBar },
  { path: "/upload-file", component: UploadFile },
  { path: "/tab-view", component: TabView },
  { path: "/fieldset", component: FieldSet },
  { path: "/filter-panel", component: FilterPanel },
  { path: "/toolbar", component: Toolbar },
  { path: "/data-source", component: DataSource },
  { path: "/validation", component: Validation },
  { path: "/context-menu", component: ContextMenu },
  { path: "/query-builder", component: QueryBuilder },
  { path: "/color-picker", component: ColorPicker },
  { path: "/drawer", component: Drawer },
  { path: "/loading-indicator", component: LoadingIndicator },
  { path: "/loading-panel", component: LoadingPanel },
  { path: "/custom", component: CustomPopup},
  { path: "/time-picker", component: TimePicker},
  { path: "/components-get-started", component: ComponentsGetStarted },

  // Grids
  { path: "/grids-get-started", component: GridsGetStarted },
  { path: "/grids", component: DataGrid },
  { path: "/data-grid", component: DataGrid },
  { path: "/data-tree", component: DataTree },
  { path: "/data-lov", component: DataLOV },
  { path: "/command-column", component: CommandColumn },
  { path: "/date-column", component: DateColumn },
  { path: "/selection-column", component: SelectionColumn },
  { path: "/text-column", component: TextColumn },
  { path: "/row-number-column", component: RowNumber },

  //Layouts
  { path: "/layouts-get-started", component: LayoutsGetStarted },
  { path: "/layouts", component: DockLayout },
  { path: "/dock-layout", component: DockLayout },
  { path: "/dock-panel", component: DockPanel },
  { path: "/dock-panel-content", component: DockPanelContent },

  //service
  { path: "/component-factory", component: ComponentFactoryService },
  { path: "/event", component: EventService },
  { path: "/injection", component: InjectionService },
  { path: "/storage", component: StorageService },
  { path: "/navigator", component: NavigatorService },
  { path: "/translator", component: TranslatorService },
  { path: "/dialog-service", component: DialogService },
  { path: "/popup-service", component: PopupService },
  { path: "/loading-service", component: LoadingService },

  //class
  { path: "/ax-select-item", component: AXSelectItem },
  { path: "/ax-selection-list", component: AXSelectionList },
  { path: "/ax-navigator-param", component: AXNavigatorParam },
  { path: "/ax-base-menu-item", component: AXBaseMenuItem },
  { path: "/ax-menu-item", component: AXMenuItem },
  { path: "/ax-button-item", component: AXButtonItem },
  { path: "/ax-check-item", component: AXCheckItem },
  { path: "/ax-color", component: AXColor },
  { path: "/closing-event-args", component: ClosingEventArgs },
  { path: "/closing-action", component: ClosingAction },
  { path: "/closed-event-args", component: ClosedEventArgs },
  { path: "/ax-calendar-month", component: AXCalendarMonth },
  { path: "/ax-promise", component: AXPromise },
  { path: "/ax-date-time", component: AXDateTime },
  { path: "/ax-date-time-rang", component: AXDateTimeRange },
  { path: "/ax-color-util", component: AXColorUtil },
  { path: "/test", component: Test },
  { path: "/ax-base-page-component", component: AXBasePageComponent },
];
const APP_ROUTES = [
  { path: "/", exact: true, component: Home },
  { path: "/404", component: null },
  {
    path: "/",
    component: (props) => {
      return <Main {...props} mainRoutes={MAIN_ROUTES} />;
    },
  },
];

export default APP_ROUTES;
