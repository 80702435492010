import React from 'react';
import {NavLink} from 'react-router-dom';
function ListSideBar(props) {
    const name=props.item.name;

    const renderParent=(item)=>{
        const result = [];
        item.forEach(element => {
            result.push(
                <ul>
                    <li>
                        <NavLink to={element.url}>
                            {element.name}
                        </NavLink>
                    </li>
                    {
                        element.parent ? (
                            renderParent(element.parent)
                        ) : (
                            null
                        )
                    }
                </ul>
            )
        });
        return (result);
    }
    if(name.toLowerCase().indexOf(props.filterText.toLowerCase()) > -1)
        return (
            <li>
                <NavLink to={props.item.url}>
                {props.item.name}
                </NavLink>
                {
                    props.item.parent ? (
                        renderParent(props.item.parent)
                    ) : (
                        null
                    )
                }
            </li>
        );
    else
        return false;
}

export default ListSideBar