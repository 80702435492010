import React, { useState } from 'react';
import {  HtmlTab, TypeScriptTab } from "./index";
import { Tabs, Tab } from 'react-bootstrap';
function TabComponent(props) {
    const [key, setKey] = useState('HTML');
    return (
        <Tabs id="controlled-tab-example" activeKey={key} onSelect={k => setKey(k)}>
            <Tab eventKey="HTML" title="HTML">
                <HtmlTab src={props.Html} />
            </Tab>
            <Tab eventKey="Ts" title="TS">
                <TypeScriptTab src={props.TypeScript} />
            </Tab>
        </Tabs>
    )
}

export default TabComponent