import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/objectWithoutPropertiesLoose";
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

function SecureRoute(props) {
    var restProps = _objectWithoutPropertiesLoose(props, ["roles"]);

    return React.createElement(Route, restProps);
}

SecureRoute.propTypes = {
    roles: PropTypes.array
};
export default SecureRoute;