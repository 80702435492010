import React, { useState } from "react";
import { ListSideBar } from "./index";
import Url from "../docs/Component/Url";
import ServiceUrl from "../docs/Service/Url.json";
import GridsUrl from "../docs/Grids/GridsUrl";
import LayoutsUrl from "../docs/Layouts/LaUrl";
import ClassUrl from "../docs/Class/ClassUrl.json";
function SideBar(props) {
  const [Search, setSearch] = useState("");
  const [showSidebar, setShowSidebar] = useState(false);

  const renderLink = () => {
    var result = [];
    Url.forEach((item) => {
      result.push(<ListSideBar key={item.id} item={item} filterText={Search} />);
    });
    return result;
  };

  const renderServiceLink = () => {
    var result = [];
    ServiceUrl.forEach((item) => {
      result.push(<ListSideBar key={item.id} item={item} filterText={Search} />);
    });
    return result;
  };

  const renderLinkGrid = () => {
    var result = [];
    GridsUrl.forEach((item) => {
      result.push(<ListSideBar key={item.id} item={item} filterText={Search} />);
    });
    return result;
  };
  const renderLinkLayout = () => {
    var result = [];
    LayoutsUrl.forEach((item) => {
      result.push(<ListSideBar key={item.id} item={item} filterText={Search} />);
    });
    return result;
  };

  const renderClassLink = () => {
    var result = [];
    ClassUrl.forEach((item) => {
      result.push(<ListSideBar key={item.id} item={item} filterText={Search} />);
    });
    return result;
  };

  return (
    <div className="col-xl-2 col-md-3 col-12 sideNav-sidePanel">
      <div className="d-flex align-items-center">
        <input
          type="text"
          placeholder="Search"
          className="w-100 my-2 form-control"
          onChange={(event) => setSearch(event.target.value)}
          autoComplete="off"
        />
        <button className="sidebar-menu" onClick={() => setShowSidebar(!showSidebar)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30" focusable="false">
            <title>Menu</title>
            <path stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M4 7h22M4 15h22M4 23h22"></path>
          </svg>
        </button>
      </div>
      <ul className={showSidebar ? "show" : ""}>
        <ListSideBar item={{ name: "Get Started", url: "get-started" }} filterText={Search} />
        <ListSideBar item={{ name: "Styles", url: "styles" }} filterText={Search} />
        <ListSideBar item={{ name: "Components", url: "components" }} filterText={Search} />
        <ListSideBar item={{ name: "Get Started", url: "components-get-started" }} filterText={Search} />
        <ul>{renderLink()}</ul>
        <ListSideBar item={{ name: "Grids", url: "grids" }} filterText={Search} />
        <ListSideBar item={{ name: "Get Started", url: "grids-get-started" }} filterText={Search} />
        <ul>{renderLinkGrid()}</ul>
        <ListSideBar item={{ name: "Layouts", url: "layouts" }} filterText={Search} />
        <ListSideBar item={{ name: "Get Started", url: "layouts-get-started" }} filterText={Search} />
        <ul>{renderLinkLayout()}</ul>
        <ListSideBar item={{ name: "Services", url: "serice" }} filterText={Search} />
        <ul>{renderServiceLink()}</ul>
        <ListSideBar item={{ name: "Classes", url: "class" }} filterText={Search} />
        <ul>{renderClassLink()}</ul>
      </ul>
    </div>
  );
}

export default SideBar;
