import React, { useEffect } from "react";
function ServiceGrid(props) {
  const renderTable = () => {
    const result = [];
    props.src.forEach((item) => {
      result.push(
        <div>
          <h4>{item.name}</h4>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>{renderRowsTable(item.id)}</tbody>
          </table>
        </div>
      );
    });
    return result;
  };

  const renderRowsTable = (id) => {
    const result = [];
    props.src
      .find((item) => item.id === id)
      .result.forEach((item) => {
        result.push(
          <tr>
            <td>{item.name}</td>
            <td>{item.type}</td>
            <td>{item.description}</td>
          </tr>
        );
      });
    return result;
  };
  return <div className="document">{renderTable()}</div>;
}

export default ServiceGrid;
