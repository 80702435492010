import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import PropTypes from "prop-types";
import {SwitchRouter} from './demo/components';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
const PageLoading = () => <div>Loading...</div>;
const App = ({ store, appRoutes }) => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<PageLoading />}>
          <SwitchRouter routes={appRoutes} />
        </Suspense>
      </BrowserRouter>
    </Provider>
  )
};
App.propTypes = {
  store: PropTypes.object.isRequired,
  appRoutes: PropTypes.array.isRequired
};
export default App;