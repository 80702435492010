import React from "react";
import { MarkDown, Code, ClassTab, ServiceGrid } from "./index";

function ClassTemplateWrapperr(props) {
  return (
    <div>
      <h1>{props.title}</h1>
      {props.Description ? <MarkDown src={props.Description} /> : <div></div>}
      {props.Demo || props.Html || props.Document || props.TypeScript ? (
        <Code Demo={props.Demo} Html={props.Html} Document={props.Document} TypeScript={props.TypeScript} />
      ) : null}

      {props.Desc ? <MarkDown src={props.Desc} /> : null}

      {props.Document ? (
        <div>
          {/* <h4>Properties</h4>
          <DocumentTab src={props.Document.Property} /> */}
          {props.Document.Property.length > 0 ? (
            <div>
              <h4>Properties</h4>
              <ClassTab src={props.Document.Property} />
            </div>
          ) : null}

          {/* <h4>Events</h4>
          <DocumentTab src={props.Document.Event} /> */}
          {props.Document.Event.length > 0 ? (
            <div>
              <h4>Events</h4>
              <ClassTab src={props.Document.Event} />
            </div>
          ) : null}

          {props.Document.Method.length > 0 ? (
            <div>
              <h4>Methods</h4>
              <ClassTab src={props.Document.Method} />
            </div>
          ) : null}
        </div>
      ) : null}

      {props.Service ? <ServiceGrid src={props.Service} /> : null}
      <br></br>
    </div>
  );
}

export default ClassTemplateWrapperr;
