import React from 'react';

function Demo(props) {
    return (
        <iframe height={props.height | 500} title="iframe" src={props.src} className="iframe">

        </iframe>
    )
}

export default Demo