import React from "react";
import { MarkDown, Code, DocumentTab, ServiceGrid } from "./index";

function ClassTemplateWrapper(props) {
  return (
    <div>
      <h1>{props.title}</h1>
      {props.Description ? <MarkDown src={props.Description} /> : <div></div>}
      <Code Demo={props.Demo} Html={props.Html} Document={props.Document} TypeScript={props.TypeScript} />

      {props.Desc ? <MarkDown src={props.Desc} /> : null}

      {props.Document ? <ServiceGrid src={props.Document} /> : null}
    </div>
  );
}

export default ClassTemplateWrapper;