import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
function Header() {
    return (
        <Navbar sticky="top" collapseOnSelect expand="lg">
            <Container fluid={true}>
                <NavLink className="navbar-brand" to="/">
                    <img alt="" src="/logo-color.svg" width="100" className="d-inline-block align-top mr-2 my-2" />
                </NavLink>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" className="mr-2" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto pl-4">
                        <NavLink className="nav-link" to="/">Home</NavLink>
                        <NavLink className="nav-link" to="/get-started">Get Started</NavLink>
                    </Nav>
                    <Nav className="pl-4 my-2 align-center">
                        <Nav.Link eventKey={2} href="#" className="line-height-30">
                            Version 3.0.47
                        </Nav.Link>
                        <Nav.Link href="#github">
                            <i className="fab fa-github fa-2x"></i>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header