import React from 'react';
import { MarkDown } from "./index";

function HtmlTab(props) {

    return (
        <MarkDown src={props.src} />
    )
}

export default HtmlTab