import React from 'react';
import { SwitchRouter } from "./index";
function Content(props) {
    var {
        mainRoutes
    } = props;
    return (
        <div className="col-xl-10 col-md-9 col-12 main-styles">
            {
                React.createElement(SwitchRouter, {
                    routes: mainRoutes
                })
            }
        </div>
    )
}

export default Content