import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { TabComponent, Demo } from './index';
function Code(props) {
    const [showTab, setShowTab] = useState(false);
    const Tab = () => {
        if (showTab) {
            return (
                <TabComponent Html={props.Html} TypeScript={props.TypeScript} />
            )
        }
    }
    return (
        <Card>
            <Card.Header className="d-flex justify-space-between align-center">
                <span>Basic Usage</span>
                <img alt="codeButton" className="codeButton" width="24" src="images/code.svg" onClick={() => setShowTab(!showTab)} />
            </Card.Header>
            <Card.Body>
                {Tab()}
                {
                    props.Demo !=='' ? (
                        <Demo src={props.Demo} />
                    ) : (
                        <div></div>
                    )
                }
            </Card.Body>
        </Card>
    )
}

export default Code