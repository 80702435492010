import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
function MarkDown(props) {

    const [Data, setData] = useState(() => {
        fetch(props.src).then((response) => response.text()).then((text) => {
            setData(text);
            return text;
        });
    });




    return (
        <ReactMarkdown source={Data} className="markdown" />
    )
}

export default MarkDown