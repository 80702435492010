import React from 'react';
import { Header, Sidebar, Content } from "./components";
function Main(props) {

    return (
        <div>
            <Header />
            <div className="container-fluid">
                <div className="row">
                    <Sidebar mainRoutes={props.mainRoutes} />
                    <Content mainRoutes={props.mainRoutes} />
                </div>
            </div>
        </div>
    )
}

export default Main