import React from 'react';
import PropTypes from "prop-types";

function DocumentTab(props) {
    const rows = [];

    props.src.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
    });
    props.src.forEach((item,index) => {
        rows.push(
            <tr key={index}>
                <td>{item.name}</td>
                <td>{item.defaultValue}</td>
                <td>{item.type}</td>
                <td class="td-values">{item.values}</td>
                <td>{item.description}</td>
            </tr>
        )
    });

    return (
        <div className="document">
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Default Value</th>
                        <th>Type</th>
                        <th>Values</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
        </div>
    )
}

Document.propTypes = {
    src: PropTypes.array
};

Document.defaultProps = {
    src: "{}"
}

export default DocumentTab